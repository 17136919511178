import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App'
import reportWebVitals from './reportWebVitals';
import i18n from './i18n/i18n';
import { I18nextProvider } from 'react-i18next';

window.onload = () => {
    console.log('language :: ', window.navigator.language)
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <I18nextProvider i18n={i18n(window.navigator.language)}>
        <App />
    </I18nextProvider>
);
reportWebVitals();
