import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { auth } from '../fbInstance';
import { signInWithCustomToken } from 'firebase/auth';
import axios from "axios"
import Loading from "./component/loadingProgressComponent";

const LineCallback = () => {

    const navigate = useNavigate();

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`

    useEffect(() => {

        const params = new URL(document.location.toString()).searchParams;
        const code = params.get('code');
        const state = params.get('state');
        const grantType = "authorization_code";
        const client_id = process.env.REACT_APP_LINE_CLIENT_ID
        const client_secret = process.env.REACT_APP_LINE_CLIENT_SECRET
        const REDIRECT_URI = `${process.env.REACT_APP_PROD_REDIRECT_URL}lineLoginLoading`;
        const GET_FIREBASE_TOKEN = process.env.REACT_APP_LINE_GET_FIREBASE_TOKEN_URL

        console.log("라인 code", code);
        console.log("라인 state", state);


        //라인 유저 억세스 토큰 획득
        axios.post(
            `https://api.line.me/oauth2/v2.1/token`,
            {
                grant_type: grantType,
                code: code,
                redirect_uri: REDIRECT_URI,
                client_id: client_id,
                client_secret: client_secret
            },
            { headers: { "Content-type": "application/x-www-form-urlencoded;" } }
        )
            .then((res) => {

                console.log(res);
                const { access_token } = res.data;
                console.log("억세스 토큰", access_token);

                //라인 function api 파이어베이스 토큰 획득
                axios.post(
                    GET_FIREBASE_TOKEN,
                    {
                        token: access_token
                    }
                )
                    .then((response) => {
                        console.log("성공", response);
                        const { firebase_token } = response.data;

                        signInWithCustomToken(auth, firebase_token)
                        .then((userCredential) => {
                            // Signed in
                            const user = userCredential.user;
                            //setUserData(user); // user data 설정
                            var reloadUserInfo = user.reloadUserInfo
                            navigate(
                                link_login_loading,
                                {
                                    state: {
                                        displayName: reloadUserInfo.displayName,
                                        userId: reloadUserInfo.localId
                                    }
                                }
                            )

                            console.log("로그인 성공", userCredential);
                            console.log("유저 데이터", reloadUserInfo);
                            // ...
                        })

                    })
                    .catch((Error) => {
                        console.log("에러")
                        console.log(Error)
                        navigate(
                            link_login_error,
                        )
                    })
                    
            })
            .catch((Error) => {
                console.log(Error)
                navigate(
                    link_login_error,
                )
            })

    }, [])

    return (
        <>
        <div className="divMainLogin">
            <Loading/>
        </div> 
        </>
    )

}

export default LineCallback;