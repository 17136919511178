import "./failedComponent.css";
import { useTranslation } from "react-i18next";

const CompleteComponent = () => {
  const { t } = useTranslation();
  return (
    <section className="button-dock">
      <img
        className="caution-icon"
        loading="lazy"
        alt=""
        src={process.env.PUBLIC_URL + '/assets/complete_check.svg'}
      />
      <div className="caution-icon1">
        <div className="div1">{t('login_sigout_completed_title')}</div>
        <div className="recheck-account-wrapper">
          <div className="recheck-account">
            <div className="div2">{t('login_sigout_completed_description')}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompleteComponent;