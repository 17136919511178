import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE_FOR_OS } from '../types'
 
import localeKO from './lang/lang.ko.json'
import localeEN from './lang/lang.en.json'
 
const resource = {
  [LANGUAGE_FOR_OS.EN]: { translation: localeEN },
  [LANGUAGE_FOR_OS.KO]: { translation: localeKO }
}
 
const init = (lang) => {
  i18n
    .use(initReactI18next)
    .init({
      resources: resource,
      fallbackLng: 'en-US',
      debug: true,
      interpolation: {
        escapeValue: true,
        prefix: '{',
        suffix: '}',
      },
      lng: lang,
    })
}
 
export default init;