import { useEffect } from "react";
import { firestore, auth } from '../fbInstance';
import { doc, getDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { deleteUser } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Loading from "./component/loadingProgressComponent";

const LoginCallback = () => {

    const userDatas = useLocation();
    const displayName = userDatas.state?.displayName
    const userId = userDatas.state?.userId

    const link_login_main = `${process.env.REACT_APP_BASE_URL}/main/loginMainPage`
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`
    const link_login_empty_user = `${process.env.REACT_APP_BASE_URL}/auth/emptyUserPage`

    console.log("유저 아이디"+userId);

    const navigate = useNavigate();

    async function fetchData() {

        try{
            const user = await getDoc(doc(firestore, 'users', userId));
            const userData = user.data();
            console.log("유저 데이터 :"+ userData);
            if(userData == null || userData.isDeleted == true){
                console.log("유저 데이터 없음");
                await deleteUser(auth.currentUser)
                navigate(
                    link_login_empty_user,
                )
            }else{
                console.log(userData);
                var { roomId } = userData
                console.log(roomId);
                navigate(
                    link_login_main,
                    {
                        state:{
                            displayName: displayName,
                            userId: userId,
                            roomId: roomId
                        }
                    }
                )
            }
        }catch(error){
            navigate(
                link_login_error,
            )
        }

    }

    useEffect(() => {

        fetchData()

    }, [])


    return (
        <div className="divMainLogin">
            <Loading/>
        </div>
    )
}

export default LoginCallback;