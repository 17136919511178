import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Login from './auth/login';
import KaKaoLoginLoading from './auth/kakaoLoginLoading';
import LineLoginLoading from './auth/lineLoginLoading';
import LoginLoading from './auth/loginLoading';
import EmptyUserPage from './auth/emptyUserPage';
import LoginMainPage from './main/loginMainPage';
import LoginErrorPage from './error/loginErrorPage';
import SigoutComplete from './auth/sigoutComplete';
import SigoutFailedPage from './error/sigoutFailedPage';
import NotFoundPage from './error/notFoundPage';

function App() {
  return (
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/sigout_user/auth/kakaoLoginLoading" element={<KaKaoLoginLoading />} />
            <Route exact path="/sigout_user/auth/lineLoginLoading" element={<LineLoginLoading />} />
            <Route exact path="/sigout_user/auth/loginLoading" element={<LoginLoading />} />
            <Route exact path="/sigout_user/auth/emptyUserPage" element={<EmptyUserPage />} />
            <Route exact path="/sigout_user/auth/sigoutComplete" element={<SigoutComplete />} />

            <Route exact path="/sigout_user/main/loginMainPage" element={<LoginMainPage />} />

            <Route exact path="/sigout_user/error/loginErrorPage" element={<LoginErrorPage />} />
            <Route exact path="/sigout_user/error/sigoutFailedPage" element={<SigoutFailedPage />} />

            <Route exact path="/sigout_user" element={<Login />} />

            <Route exact path="*" element={<NotFoundPage/>}/>
          </Routes>
        </Router>
      </div>
  );
}

export default App;
