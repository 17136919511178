import FailedLoginComponent from "../auth/component/failedLoginComponent.js";
import MoveLoginButton from "../auth/component/moveLoginButton.js";
import "../auth/component/unMemberPageFrame.css";

const LoginErrorPage = () => {

    return (
        <div className='divMainLogin'>
                <FailedLoginComponent />
                <MoveLoginButton />
        </div>

    )
}
export default LoginErrorPage;