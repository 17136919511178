import { auth, analytics} from '../fbInstance';
import { logEvent } from "firebase/analytics";
import { GoogleAuthProvider, signInWithPopup, OAuthProvider, FacebookAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import '../i18n/i18n';
import { v4 as uuidv4 } from 'uuid';
import "./login.css";
import Loading from "./component/loadingProgressComponent";
import { useState } from 'react';

const SocialLogin = () => {

    logEvent(analytics, 'web_impression_login', {name: 'couple_web_impression_login'});

    const { t, i18n } = useTranslation();

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`;
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`;
    const rest_api_key = process.env.REACT_APP_KAKAO_APP_KEY; //REST API KEY
    const redirect_uri = process.env.REACT_APP_PROD_REDIRECT_URL //Redirect URI
    //const redirect_uri = process.env.REACT_APP_REDIRECT_URL; //Redirect URI DEV

    const [isSigoutLoading, setSigoutLoading] = useState(false);

    const navigate = useNavigate();

    const handleKakaoLogin = () => {
        var kakaoRedirectUri = `${redirect_uri}kakaoLoginLoading`

        // oauth 요청 URL
        const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${kakaoRedirectUri}&response_type=code`
        window.location.href = kakaoURL
    }

    const handleLineLogin = () => {
        var lineRedirectUri = `${redirect_uri}lineLoginLoading`
        var uuid = uuidv4();
        const LineURL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_CLIENT_ID}&redirect_uri=${lineRedirectUri}&state=${uuid}&scope=profile`

        window.location.href = LineURL
    }

    function handleGoogleLogin() {

        setSigoutLoading(true)

        var googleprovider = new GoogleAuthProvider(); // provider를 구글로 설정
        googleprovider.setCustomParameters({
            prompt: 'select_account'
        })
        signInWithPopup(auth, googleprovider) // popup을 이용한 signup
            .then((data) => {
                console.log(data) // console로 들어온 데이터 표시

                var reloadUserInfo = data.user.reloadUserInfo
                console.log("첫번쨰 유저Id" + reloadUserInfo.localId);

                navigate(
                    link_login_loading,
                    {
                        state: {
                            displayName: reloadUserInfo.displayName,
                            userId: reloadUserInfo.localId
                        }
                    }
                )
                setSigoutLoading(false)
            })
            .catch((err) => {
                console.log("구글 로긴 에러", err);
                navigate(
                    link_login_error,
                )
                setSigoutLoading(false)
            });
    }

    function handleAppleLogin() {
        setSigoutLoading(true)

        var appleProvider = new OAuthProvider("apple.com");
        signInWithPopup(auth, appleProvider)
            .then((data) => {
                // You can also get the Apple OAuth Access and ID Tokens.
                const credential = OAuthProvider.credentialFromResult(data);

                var reloadUserInfo = data.user.reloadUserInfo
                navigate(
                    link_login_loading,
                    {
                        state: {
                            displayName: reloadUserInfo.displayName,
                            userId: reloadUserInfo.localId
                        }
                    }
                )

                const accessToken = credential.accessToken;
                const idToken = credential.idToken;

                setSigoutLoading(false)
                console.log(data) // console로 들어온 데이터 표시
            })
            .catch((err) => {
                console.log(err);
                // Handle Errors here.
                const errorCode = err.code;
                const errorMessage = err.message;
                // The email of the user's account used.
                const email = err.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(err);

                navigate(
                    link_login_error,
                )

                setSigoutLoading(false)
            });

    }

    function handleFaceBookLogin() {
        setSigoutLoading(true)
        
        var facebookProvider = new FacebookAuthProvider();
        facebookProvider.setCustomParameters({
            display: 'popup',
            auth_type: 'reauthenticate'
        })
        signInWithPopup(auth, facebookProvider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;

                var reloadUserInfo = result.user.reloadUserInfo
                console.log("첫번쨰 유저Id" + reloadUserInfo.localId);
                navigate(
                    link_login_loading,
                    {
                        state: {
                            displayName: reloadUserInfo.displayName,
                            userId: reloadUserInfo.localId
                        }
                    }
                )
                setSigoutLoading(false)
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {

                setSigoutLoading(false)

                // Handle Errors here.
                console.log("페북 로그인 실패" + error);
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);

                // ...

                navigate(
                    link_login_error,
                )
            });

    }




    return (
        <>
            {isSigoutLoading ?
                <div className="divMainLogin">
                    <Loading />
                </div>

                :

                <div className='divMainLogin'>
                    <div className="divLogin">
                        <section className="parentLogin">
                            <h3 className="h3Login">
                                <p className="pLogin">{i18n.t('login_title').split("\n").map((line)=>(<div>{line}</div>))}</p>
                            </h3>
                            <img
                                className="img-main-logo-valentine-iconLogin"
                                loading="lazy"
                                alt=""
                                src="/assets/img_main_logo_normal.svg"
                            />
                        </section>
                        <section className="groupLogin">
                            <div className="div1Login">{t('login_description')}</div>
                            <div>
                                <button className="buttonLogin" onClick={handleGoogleLogin}>
                                    <div className="contentLogin">
                                        <img className="icon-googleLogin" alt="" src="/assets/icon_google.svg" />
                                        <div className="labelLogin">{t('login_sign_in_google')}</div>
                                    </div>
                                </button>
                            </div>
                            <div>
                                <button className="button1Login" onClick={handleAppleLogin}>
                                    <div className="content1Login">
                                        <img className="icon-appleLogin" alt="" src="/assets/icon_apple.svg" />
                                        <div className="label1Login">{t('login_sign_in_apple')}</div>
                                    </div>
                                </button>
                            </div>
                            <div>
                                <button className="button2Login" onClick={handleKakaoLogin}>
                                    <div className="content2Login">
                                        <img className="icon-kakaoLogin" alt="" src="/assets/icon_kakao.svg" />
                                        <div className="label2Login">{t('login_sign_in_kakao')}</div>
                                    </div>
                                </button>
                            </div>
                            <div>
                                <button className="button3Login" onClick={handleFaceBookLogin}>
                                    <div className="content3Login">
                                        <img className="icon-facebookLogin" alt="" src="/assets/icon_facebook.svg" />
                                        <div className="label3Login">{t('login_sign_in_facebook')}</div>
                                    </div>
                                </button>
                            </div>
                            <div>
                                <button className="button4Login" onClick={handleLineLogin}>
                                    <div className="content4Login">
                                        <img className="icon-lineLogin" alt="" src="/assets/icon_line.svg" />
                                        <div className="label4Login">{t('login_sign_in_line')}</div>
                                    </div>
                                </button>
                            </div>
                        </section>
                    </div>
                </div>
            }

        </>
    )
}
export default SocialLogin

// <img src={`${process.env.PUBLIC_URL}/assets/login_button_kakao.svg`} onClick={handleKakaoLogin}></img><br />
// <img src={`${process.env.PUBLIC_URL}/assets/login_button_google.svg`} onClick={handleGoogleLogin}></img><br />
// <img src={`${process.env.PUBLIC_URL}/assets/login_button_facebook.svg`} onClick={handleFaceBookLogin}></img><br />
// <img src={`${process.env.PUBLIC_URL}/assets/login_button_line.svg`} onClick={handleLineLogin}></img><br />
// <img src={`${process.env.PUBLIC_URL}/assets/login_button_apple.svg`} onClick={handleAppleLogin}></img><br />