import SigoutFailedComponent from "../auth/component/sigoutfailedComponent.js";
import MoveLoginButton from "../auth/component/moveLoginButton.js";
import "../auth/component/unMemberPageFrame.css";

const SigoutFailedPage = () => {

    return (
        <div className='divMainLogin'>
                <SigoutFailedComponent />
                <MoveLoginButton />
        </div>
    )
}
export default SigoutFailedPage;