import React from 'react';
import './confirmSigout.css';
import { useTranslation } from "react-i18next";

function CustomUi(props) {

    const { t } = useTranslation();

    return (
        <section className="scrim" >
            <div className="dialogDialog">
                <div className="container-text-groupDialog">
                    <div className="text-titleDialog">{t('login_main_delete_dialog_title')}</div>
                    <div className="dialog-partsparagraph-textDialog">
                        <div className="contents-paragraphDialog">
                        {t('login_main_delete_dialog_description')}
                        </div>
                    </div>
                </div>
                <div className="button-dock1Dialog">
                    <div className="container-button-group1Dialog">
                        <button className="button-button-21Dialog" 
                        onClick={() => {
                            props.onClose();
                            props.onPositive();
                        }}>
                            <div className="container7Dialog">
                                <div className="text-label7Dialog">{t('login_main_delete_dialog_sigout')}</div>
                            </div>
                        </button>
                        <button className="button-button-11Dialog">
                            <div className="container8Dialog">
                                <div className="text-label8Dialog" onClick={props.onClose}>{t('login_main_delete_dialog_close')}</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CustomUi;
