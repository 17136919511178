import NotFoundComponent from "../auth/component/notFoundComponent.js";
import MoveLoginButton from "../auth/component/moveLoginButton.js";
import "../auth/component/unMemberPageFrame.css";

const NotFoundPage = () => {

    return (
        <div className='divMainLogin'>
                <NotFoundComponent />
                <MoveLoginButton />
        </div>
    )
}
export default NotFoundPage;