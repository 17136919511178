import React from "react";
import { ReactNode, SVGProps } from "react";

interface Props {
  children?: ReactNode | ReactNode[];
  svgProps?: Omit<SVGProps<SVGSVGElement>, 'children'>;
}

function Svg({ children, svgProps }: Props) {
  return (
    <svg {...svgProps} >
      {children}        
    </svg>
  );
}

export default Svg;