import "./failedComponent.css";
import { useTranslation } from "react-i18next";

const NotFoundComponent = () => {

  const { t, i18n } = useTranslation();

  return (
    <section className="button-dock">
      <img
        className="caution-icon"
        loading="lazy"
        alt=""
        src={process.env.PUBLIC_URL + '/assets/exclamation_mark.svg'}
      />
      <div className="caution-icon1">
        <div className="div1">{t('login_not_found_title')}</div>
        <div className="recheck-account-wrapper">
          <div className="recheck-account">
            <div className="div2">{i18n.t('login_not_found_description').split("\n").map((line)=>(<div>{line}</div>))}</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundComponent;