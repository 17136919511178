import Loading from "./loading.tsx";
import "./failedComponent.css";
import { useTranslation } from "react-i18next";

const LoadingProgressComponent = () => {
    const { t } = useTranslation();
    return (
        <section className="button-dock">
            <Loading />
            <div className="caution-icon2">
                <div className="div1">{t('login_loading_title')}</div>
                <div className="recheck-account-wrapper">
                    <div className="recheck-account">
                        <div className="div2">{t('login_loading_wait')}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoadingProgressComponent;